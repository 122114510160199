
import { interactiveDemo2023Gql } from '../landing_page_2023/interactiveDemoGql.js';
import { interactiveWebglDemo2023Gql } from '../landing_page_2023/interactiveWebglDemoGql.js';
import { form2023Gql } from '../landing_page_2023/formGql.js';
import { disclaimerFootnote2023Gql } from '../landing_page_2023/disclaimerFootnoteGql.js';
import { heroBanner2023Gql } from '../landing_page_2023/heroBannerGql.js';
import { basicText2023Gql } from '../landing_page_2023/basicTextGql.js';
import { chart2023Gql } from '../landing_page_2023/chartGql.js';
import { featuredContent2023Gql } from '../landing_page_2023/featuredContentGql.js';
import { pageHeader2023Gql } from '../landing_page_2023/pageHeaderGql.js';
import { productHeader2023Gql } from '../landing_page_2023/productHeaderGql.js';
import { promoBlockGql } from '../landing_page_2023/promoBlockGql.js';
import { cSPromoBlock2023Gql } from '../landing_page_2023/cSPromoBlockGql.js';
import { quoteSlider2023Gql } from '../landing_page_2023/quoteSliderGql.js';
import { relatedResources2023Gql } from '../landing_page_2023/relatedResourcesGql.js';
import { textAndContent2023Gql } from '../landing_page_2023/textAndContentGql.js';
import { textAndImage2023Gql } from '../landing_page_2023/textAndImageGql.js';
import { videoGallery2023Gql } from '../landing_page_2023/videoGalleryGql.js';
import { videoPlayer2023Gql } from '../landing_page_2023/videoPlayerGql.js';
import { linksGql } from '../landing_page_2023/linksGql.js';
import { relatedProductsGql } from '../landing_page_2023/relatedProductsGql.js';

export const landingPageContent2023Gql = `landingPageContent2023 {
  ${interactiveWebglDemo2023Gql}
  ${interactiveDemo2023Gql}
  ${form2023Gql}
  ${disclaimerFootnote2023Gql}
  ${heroBanner2023Gql}
  ${basicText2023Gql}
  ${chart2023Gql}
  ${featuredContent2023Gql}
  ${pageHeader2023Gql}
  ${productHeader2023Gql}
  ${promoBlockGql}
  ${cSPromoBlock2023Gql}
  ${quoteSlider2023Gql}
  ${relatedResources2023Gql}
  ${textAndContent2023Gql}
  ${textAndImage2023Gql}
  ${videoGallery2023Gql}
  ${videoPlayer2023Gql}
  ${linksGql}
  ${relatedProductsGql}
}`;
