export const heroBanner2023Gql = `... on landingPageContent2023_heroBanner_BlockType{
    typeHandle
    desktopBannerHeight
    desktopBannerAspectRatio
    mobileBannerHeight
    mobileBannerAspectRatio
    indicatorType
    autoplaySlides
    slideDuration
    slide{
      ... on slide_BlockType{
        slideName
        slideIcon {
          ... on svgIcons_Asset{
            title
            url
          }
        }
        contentEyebrow
            contentHeader
            contentDescription
            primaryCtaBtn
            primaryCtaBtnLabel
            primaryCtaBtnAriaLabel
            primaryCtaBtnTheme
            primaryCtaBtnOpenInANewWindow
            secondaryCtaBtn
            secondaryCtaBtnLabel
            secondaryCtaBtnAriaLabel
            secondaryCtaBtnTheme
            secondaryCtaBtnOpenInANewWindow
            backgroundVideo {
              id
                ... on videoUploads_Asset{
                    url
                    size
                    extension
                }
            }
            contentImage {
                id
                ... on images_Asset{
                  title
                  lpImageTextUncropped{
                    src
                    srcset
                  }
                  aspectRatio169{
                    src
                    srcset
                  }
                  aspectRatio11 {
                    src
                    srcset
                  }
                  aspectRatio1851 {
                    src
                    srcset
                  }
                  aspectRatio2351 {
                    src
                    srcset
                  }
                  aspectRatio53 {
                    src
                    srcset
                  }
                  aspectRatio53 {
                    src
                    srcset
                  }
                }
              }
            contentImageHeightDesktop
            contentPositionDesktop
            contentPositionMobile
            stackTextImageMobile
            colorTheme
            backgroundImageDesktop{
              ... on images_Asset{
                title
                lpImageTextUncropped{
                      src
                      srcset
                  }
                aspectRatio169{
                    src
                    srcset
                  }
                aspectRatio11 {
                  src
                  srcset
                }
                aspectRatio1851 {
                  src
                  srcset
                }
                aspectRatio2351 {
                  src
                  srcset
                }
                aspectRatio53 {
                  src
                  srcset
                }
                aspectRatio53 {
                  src
                  srcset
                }
              }
            }
            backgroundImageMobile{
              ... on images_Asset{
                title
                lpImageTextUncropped{
                      src
                      srcset
                  }
                aspectRatio169 {
                  src
                      srcset
                }
                aspectRatio11 {
                  src
                  srcset
                }
                aspectRatio1851 {
                  src
                  srcset
                }
                aspectRatio2351 {
                  src
                  srcset
                }
                aspectRatio53 {
                  src
                  srcset
                }
                aspectRatio75 {
                  src
                  srcset
                }
                aspectRatioMobile1185 {
                  src
                  srcset
                }
                aspectRatioMobile1235 {
                  src
                  srcset
                }
                aspectRatioMobile35 {
                  src
                  srcset
                }
                aspectRatioMobile57 {
                  src
                  srcset
                }
                aspectRatioMobile916 {
                  src
                  srcset
                }
              }
            }
            backgroundOverlayOpacity
            contentBackgroundBoxEnabled
            enableTextShadows
            textShadowOffset
            textShadowBlurStrength
      }
    }
}`;
