export const textAndImage2023Gql = `... on landingPageContent2023_textAndImage_BlockType {
  pageTitle
  displayInHeaderQuicklinks
  image {
    ... on images_Asset {
      url
      title
      aspectRatio169 {
        src
      }
      lpImageTextUncropped {
        srcWebp
        srcset
      }
    }
    ... on svgIcons_Asset {
      url
      title
    }
  }
  backgroundVerticalPosition
  backgroundOpacity
  description
  typeHandle
  label
  layoutStyle
  ctaButton {
    ... on ctaButton_BlockType {
      asset {
        url
      }
      entry {
        uri
      }
      custom
      buttonStyling
      buttonLabel
      ariaLabel
      openInANewWindow
    }
  }
  colors
}`;
