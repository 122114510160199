export const productTabRelatedResourcesGql = `
  url
  title
  relatedResourceButtonOutline
  relatedResourcesButtonTextColor
  relatedResourceCardStyling
  relatedResourceDescription
  relatedResourceLanguage(label: true)
  relatedResourceSubtitle
  relatedResourceTitle
  relatedResourceButtonLabel
  relatedResourceLinkOverride
  relatedResourceVideoId
  relatedResourceImage {
    ... on images_Asset {
      aspectRatioMobile35 {
        src
      }
    }
  }
  relatedResourceCategory {
    ... on relatedResources_Category {
      title
      slug
    }
  }
`;
